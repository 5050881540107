import React from 'react';
import { Col, Row } from 'antd';
import stock_mobile from '../../assets/images/stock_mobile.webp'
import crypto_mobile from '../../assets/images/crypto_mobile.webp'
import stocks_pc from '../../assets/images/stocks_pc.webp'
import crypto_pc from '../../assets/images/crypto_pc.webp'
import more_winnings from '../../assets/images/more_winnings.webp'
import more_winnings_xxl from '../../assets/images/more_winnings_xxl.webp'

const MoreMarket = () => {
    return (
        <>
            {/* Mobile View */}
            <section className='tw-bg-primaryBg tw-block lg:tw-hidden  tw-py-4'>

                <div className='tw-bg-[#413049] 320:tw-px-4 tw-px-5 425:tw-px-10 md:tw-px-40 tw-py-[54px] '>

                    <p className='tw-font-primaryFonts tw-text-secondary_text  tw-text-[28px] 320:tw-text-[24px] tw-leading-[125%] tw-font-bold'>
                        More Markets To Play<br /> <span className='tw-text-primary_text'> = More Winnings</span>
                    </p>
                    <div className='tw-grid tw-grid-cols-2 tw-gap-3 tw-my-8'>
                        {/* Stocks */}
                        <div className='tw-bg-[#CDBED4] tw-pt-3 tw-px-3 tw-rounded-[16px]'>
                            <div className='tw-mt-2 tw-flex tw-justify-center tw-items-center'>
                                <img src={stock_mobile} width={62} height={62} alt='stocks' />
                            </div>
                            <div className='tw-my-4'>
                                <p className='tw-font-primaryFonts tw-text-[#413049] tw-font-semibold tw-text-[22px] tw-leading-125 tw-text-center'>
                                    Stocks
                                </p>
                                <p className='tw-mt-3 tw-font-secondaryFonts tw-text-[#413049]  tw-text-[13px] tw-leading-125 tw-text-center'>
                                    Play with stocks of publicly listed US companies
                                </p>
                            </div>
                        </div>
                        {/* Crypto */}
                        <div className='tw-bg-[#CDBED4] tw-pt-3 tw-px-3 tw-rounded-[16px]'>
                            <div className='tw-mt-2 tw-flex tw-justify-center tw-items-center'>
                                <img src={crypto_mobile} width={62} height={62} alt='stocks' />
                            </div>
                            <div className='tw-my-4'>
                                <p className='tw-font-primaryFonts tw-text-[#413049] tw-font-semibold tw-text-[22px] tw-leading-125 tw-text-center'>
                                    Crypto
                                </p>
                                <p className='tw-mt-3 tw-font-secondaryFonts tw-text-[#413049]  tw-text-[13px] tw-leading-125 tw-text-center'>
                                    Play 24x7 on all major cryptocurrencies
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

            </section>
            {/* Desktop View */}
            <section className='tw-bg-primaryBg tw-hidden lg:tw-block'>

                <Row className='tw-max-w-screen-3xl 3xl:tw-mx-auto tw-py-[120px]  tw-ml-20'>
                    <Col span={14} xxl={12} className='3xl:tw-flex 3xl:tw-justify-center 3xl:tw-items-center'>
                        <div>
                            <p className='tw-my-3 tw-font-primaryFonts tw-text-secondary_text  tw-text-[56px] tw-leading-[125%] tw-font-bold'>
                                More Markets To Play<br /> <span className='tw-text-primary_text'> = More Winnings</span>
                            </p>
                            <div className='tw-my-20 tw-grid tw-grid-cols-2 tw-gap-6 3xl:tw-gap-5'>
                                {/* Stocks */}
                                <div className='tw-bg-secondBg 3xl:tw-w-[330px] tw-rounded-[24px] tw-p-8'>
                                    <div className='tw-mt-2 tw-mb-4'>
                                        <img src={stocks_pc} width={78} height={78} alt='stocks' />
                                    </div>
                                    <div>
                                        <p className='tw-my-4 tw-font-secondaryFonts tw-font-bold tw-text-secondary_text tw-text-[32px] tw-leading-125'>Stocks</p>
                                        <p className='tw-font-primaryFonts tw-text-opacity-80 tw-text-secondary_text tw-text-[16px] tw-leading-125'>Play with stocks of publicly listed<br className='tw-hidden xl:tw-block' /> US companies</p>
                                    </div>
                                </div>
                                {/* Crypto */}
                                <div className='tw-bg-secondBg 3xl:tw-w-[330px] tw-rounded-[24px] tw-p-8'>
                                    <div className='tw-mt-2 tw-mb-4'>
                                        <img src={crypto_pc} width={78} height={78} alt='stocks' />
                                    </div>
                                    <div>
                                        <p className='tw-my-4 tw-font-secondaryFonts tw-font-bold tw-text-secondary_text tw-text-[32px] tw-leading-125'>Crypto</p>
                                        <p className='tw-font-primaryFonts tw-text-opacity-80 tw-text-secondary_text tw-text-[16px] tw-leading-125'>Play 24x7 on all major <br /> cryptocurrencies</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Col>
                    <Col xxl={12} span={10}>
                        <div className='tw-mr-1'>
                            <img src={more_winnings} className='3xl:tw-hidden' alt='More Markets To Play' />
                            <img src={more_winnings_xxl} className='lg:tw-hidden 3xl:tw-block' alt='More Markets To Play' />
                        </div>
                    </Col>
                </Row>


            </section>



        </>
    )
}

export default MoreMarket