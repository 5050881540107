import React, { useState } from 'react'
import { Col, Modal, Row } from 'antd'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import pc_remove from '../../assets/images/pc_menu.webp'
import logo from '../../assets/logo/LOGO.webp'
import twitter from '../../assets/images/twitter.webp'
import instagram from '../../assets/images/instagram.webp'
import linkdin from '../../assets/images/linkdin.webp'
import youtube from '../../assets/images/youtube.webp'
import trading_view from '../../assets/images/trading_view.webp'

const QuickLinks = () => {

    const [isModal, setIsModal] = useState(false)
    const [mobileModal, setMobileModal] = useState(false)


    const { ref, inView } = useInView({
        triggerOnce: true, // Animation triggers only once
        threshold: 0.3, // Percentage of element visibility to trigger the animation


    });

    const AnimationVariants = {
        hidden: { opacity: 0, y: 100, },
        visible: { opacity: 1, y: 0 },
    };
    const AnimationVariants2 = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
    };

    return (
        <>

            {/* Mobile View */}
            <section className='tw-bg-primaryBg tw-block md:tw-hidden '>
                <div className='tw-mx-5 tw-pt-6 '>

                    <div>
                        <div>
                            <img src={logo} className='tw-w-[97px]' alt='stockpe logo' />
                        </div>
                        <div className='tw-mt-[11px]'>
                            <p className=' tw-text-third_text tw-font-medium tw-text-[13px] tw-leading-[130%]'><span className='tw-font-secondaryFonts'> Play, Learn, and Earn</span> ❤️</p>
                        </div>
                        <div className="tw-flex tw-items-center tw-gap-4 tw-justify-start tw-mt-5">
                            <a
                                href="https://twitter.com/stockpe"
                                target="_blank"
                                rel="noreferrer"

                            >
                                <img src={twitter} alt="stokcPe twitter" />
                            </a>
                            <a
                                href="https://www.instagram.com/stockpe.in/"
                                target="_blank"
                                rel="noreferrer"

                            >
                                <img src={instagram} alt="stokcPe instagram" />
                            </a>


                            <a
                                href="https://www.linkedin.com/company/stockpe/"
                                target="_blank"
                                rel="noreferrer"

                            >
                                <img src={linkdin} alt="stokcPe linkdin" />
                            </a>
                            <a
                                href="https://www.youtube.com/@StockPeofficial"
                                target="_blank"
                                rel="noreferrer"

                            >
                                <img src={youtube} alt="stokcPe youtube" />
                            </a>

                        </div>
                    </div>

                    <div className='tw-mt-12'>
                        <p className='tw-font-primaryFonts tw-text-secondary_text tw-text-desk-lg-3 tw-font-bold'>Quick Links</p>
                        <div className='tw-mt-5'>

                            <Link to='/'>
                                <p className='tw-mb-3 tw-cursor-pointer tw-font-light tw-font-secondaryFonts tw-text-secondary_text  tw-text-desk-lg'>Home</p>
                            </Link>
                            <Link to='https://stockpe.app/blog/' target='_blank' rel='noreferrer'>
                                <p className='tw-mb-3 tw-cursor-pointer tw-font-light tw-font-secondaryFonts tw-text-secondary_text  tw-text-desk-lg'>Blogs</p>
                            </Link>
                            <p className='tw-mb-3 tw-cursor-pointer tw-font-light tw-font-secondaryFonts tw-text-secondary_text tw-text-desk-lg' onClick={() => setMobileModal(prev => !prev)}>Contact Us</p>
                            <a href='https://stockpe.go.link/fC1Fc' target='_blank' rel='noreferrer'>
                                <p className=' tw-cursor-pointer tw-font-light tw-font-secondaryFonts tw-text-primary_text tw-text-desk-lg'>Try the app out for free!</p>
                            </a>

                        </div>
                    </div>
                    <div className='tw-my-12'>
                        <p className='tw-font-primaryFonts  tw-text-secondary_text tw-text-desk-lg-3 tw-font-bold'>Our Partners</p>
                        <div className='tw-mt-5'>
                            <div>
                                <img src={trading_view} alt='trading_view logo' width={130} height={16} />
                            </div>

                            <p className='tw-mt-3 tw-font-primaryFonts tw-text-secondary_text tw-text-[15px] tw-leading-125'>
                                StockPe has partnered with TradingView for its charting technology. A comprehensive trading platform offering market data, widgets, analytical tools and a <Link to='https://www.tradingview.com/economic-calendar/' target='_blank' rel='noreferrer' className='tw-text-[#3C7FFF]'>real time economic calendar</Link> to track key financial events.
                            </p>

                        </div>

                    </div>
                    <div className='tw-pb-12'>
                        <p className='tw-font-primaryFonts  tw-text-secondary_text tw-text-desk-lg-3 tw-font-bold'>Support</p>
                        <div className='tw-mt-5'>

                            <a href="mailto:support@stockpe.in" target='_blank' rel='noreferrer' >

                                <p className='tw-mb-3 tw-cursor-pointer tw-font-secondaryFonts tw-text-secondary_text tw-font-light tw-text-desk-lg'>Email Us</p>
                            </a>
                            <Link to='/privacy-policy'>
                                <p className='tw-mb-3 tw-cursor-pointer tw-font-secondaryFonts tw-text-secondary_text tw-font-light tw-text-desk-lg'>Privacy Policy</p>
                            </Link>
                            <Link to='/delete-my-account'>
                                <p className=' tw-cursor-pointer tw-font-secondaryFonts tw-text-delete_text tw-font-light tw-text-desk-lg'>Delete My Account</p>
                            </Link>

                        </div>

                    </div>

                </div>
            </section>

            <Modal
                centered
                closable={false}
                open={mobileModal}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}

            >
                <section className='tw-py-5 tw-px-4'>
                    <div className='tw-flex tw-justify-between tw-items-center'>
                        <p className='tw-text-primary_text tw-font-primaryFonts tw-font-semibold tw-text-[20px] tw-leading-[130%]'>Contact Us</p>
                        <div className='tw-cursor-pointer' onClick={() => setMobileModal(prev => !prev)}>
                            <img src={pc_remove} alt='stockpe global contact close' />
                        </div>

                    </div>
                    <div className='tw-mt-8'>
                        <p className='tw-font-secondaryFonts tw-text-secondary_text tw-text-opacity-60 tw-text-[13px] tw-leading-[130%] tw-font-medium '> Email</p>
                        <a href='mailto:support@stockpe.in' className='tw-mt-2 tw-font-secondaryFonts hover:tw-text-secondary_text  tw-text-secondary_text tw-text-desk-lg tw-tracking-[0.15px] tw-underline'>
                            support@stockpe.in
                        </a>
                    </div>
                </section>
            </Modal>

            {/* Desktop View */}
            <section className='tw-bg-black tw-hidden md:tw-block '>
                <div className='tw-py-12 tw-max-w-screen-3xl 3xl:tw-mx-auto md:tw-mx-12 lg:tw-mx-20'
                >

                    <Row gutter={[16, 16]}>
                        <Col className='' span={7}>
                            <motion.div
                                className="box"
                                ref={ref}
                                initial={{ opacity: 0, y: 100 }} // Initial position and opacity
                                animate={inView ? "visible" : "hidden"} // Target position and opacity
                                variants={AnimationVariants}
                                transition={{
                                    duration: 1.3,
                                    delay: 0.25,
                                    ease: [0, 0.71, 0.2, 1.01],
                                }}
                            >
                                <div>
                                    <img src={logo} className='tw-w-[160px]' alt='stockpe logo' />
                                </div>
                                <div className='tw-mt-[11px]'>

                                    <p className=' tw-text-third_text tw-font-medium tw-text-[13px] tw-leading-[130%]'><span className='tw-font-secondaryFonts'>Play, Learn, and Earn </span> ❤️ </p>
                                </div>
                                <div className="tw-flex tw-items-center tw-justify-start tw-mt-5">
                                    <a
                                        href="https://twitter.com/stockpe"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="tw-mr-5"
                                    >
                                        <img src={twitter} alt="stokcPe twitter" />
                                    </a>
                                    <a
                                        href="https://www.instagram.com/stockpe.in/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="tw-mr-5"
                                    >
                                        <img src={instagram} alt="stokcPe instagram" />
                                    </a>


                                    <a
                                        href="https://www.linkedin.com/company/stockpe/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="tw-mr-5"
                                    >
                                        <img src={linkdin} alt="stokcPe linkdin" />
                                    </a>
                                    <a
                                        href="https://www.youtube.com/@StockPeofficial"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="tw-mr-5"
                                    >
                                        <img src={youtube} alt="stokcPe youtube" />
                                    </a>

                                </div>
                            </motion.div>
                        </Col>
                        <Col className='' span={17}>
                            <div className='tw-grid tw-h-full tw-grid-cols-3'>
                                <motion.div
                                    className="box"
                                    ref={ref}
                                    initial={{ opacity: 0, y: 100 }} // Initial position and opacity
                                    animate={inView ? "visible" : "hidden"} // Target position and opacity
                                    variants={AnimationVariants}
                                    transition={{
                                        duration: 1.3,
                                        delay: 0.25,
                                        ease: [0, 0.71, 0.2, 1.01],
                                    }}
                                >

                                    <div className='tw-mr-8 tw-border-r  tw-h-full tw-border-[rgba(251,255,255,0.3)]'>
                                        <p className='tw-font-primaryFonts tw-text-secondary_text tw-text-desk-lg-1 lg:tw-text-desk-lg-3 tw-font-bold'>Quick Links</p>
                                        <div className='tw-mt-5'>

                                            <Link to='/'>
                                                <p className='tw-mb-3 tw-cursor-pointer tw-font-light tw-font-secondaryFonts tw-text-secondary_text tw-text-xs lg:tw-text-desk-lg'>Home</p>
                                            </Link>
                                            <Link to='https://stockpe.app/blog/' target='_blank' rel='noreferrer'>
                                                <p className='tw-mb-3 tw-cursor-pointer tw-font-light tw-font-secondaryFonts tw-text-secondary_text tw-text-xs lg:tw-text-desk-lg'>Blogs</p>
                                            </Link>
                                            <p className='tw-mb-3 tw-font-light tw-cursor-pointer tw-font-secondaryFonts tw-text-secondary_text tw-text-xs lg:tw-text-desk-lg' onClick={() => setIsModal(prev => !prev)}>Contact Us</p>
                                            <a href='https://stockpe.go.link/fC1Fc' target='_blank' rel='noreferrer'>
                                                <p className=' tw-cursor-pointer tw-font-light tw-font-secondaryFonts tw-text-primary_text tw-text-xs lg:tw-text-desk-lg'>Try the app out for free!</p>
                                            </a>

                                        </div>
                                    </div>

                                </motion.div>
                                <motion.div
                                    className="box"
                                    ref={ref}
                                    initial={{ opacity: 0, y: 100 }} // Initial position and opacity
                                    animate={inView ? "visible" : "hidden"} // Target position and opacity
                                    variants={AnimationVariants}
                                    transition={{
                                        duration: 1.3,
                                        delay: 0.25,
                                        ease: [0, 0.71, 0.2, 1.01],
                                    }}
                                >
                                    <div className='tw-mr-8 tw-h-full tw-border-[rgba(251,255,255,0.3)]'>
                                        <p className='tw-font-primaryFonts tw-text-secondary_text  tw-text-desk-lg-1 lg:tw-text-desk-lg-3 tw-font-bold'>Our Partners</p>
                                        <div className='tw-mt-5'>
                                            <div>
                                                <img src={trading_view} alt='trading_view logo' width={130} height={16} />
                                            </div>

                                            <p className='tw-mt-3  tw-font-light  tw-font-secondaryFonts tw-text-secondary_text tw-text-[11px] tw-leading-[130%]' >StockPe has partnered with TradingView for its charting technology. A comprehensive trading platform offering market data, widgets, analytical tools and a <Link to='https://www.tradingview.com/economic-calendar/' target='_blank' rel='noreferrer' className='tw-text-[#3C7FFF]'>real time economic calendar</Link> to track key financial events.</p>


                                        </div>
                                    </div>
                                </motion.div>


                                <motion.div
                                    className="box"
                                    ref={ref}
                                    initial={{ opacity: 0, y: 100 }} // Initial position and opacity
                                    animate={inView ? "visible" : "hidden"} // Target position and opacity
                                    variants={AnimationVariants}
                                    transition={{
                                        duration: 1.3,
                                        delay: 0.25,
                                        ease: [0, 0.71, 0.2, 1.01],
                                    }}
                                >
                                    <div className='tw-border-l tw-h-full  tw-border-[rgba(251,255,255,0.3)]'>
                                        <div className='tw-ml-8 '>
                                            <p className='tw-font-primaryFonts tw-text-secondary_text tw-text-desk-lg-1 lg:tw-text-desk-lg-3 tw-font-bold'>Support</p>
                                            <div className='tw-mt-5'>

                                                <a href="mailto:support@stockpe.in" target='_blank' rel='noreferrer'>
                                                    <p className='tw-mb-3 tw-cursor-pointer tw-font-secondaryFonts tw-text-secondary_text tw-font-light tw-text-xs lg:tw-text-desk-lg'>Email Us</p>
                                                </a>
                                                <Link to='/privacy-policy'>
                                                    <p className='tw-mb-3 tw-cursor-pointer tw-font-secondaryFonts tw-text-secondary_text tw-font-light tw-text-xs lg:tw-text-desk-lg'>Privacy Policy</p>
                                                </Link>
                                                <Link to='/delete-my-account'>
                                                    <p className=' tw-cursor-pointer tw-font-secondaryFonts tw-text-delete_text tw-font-light tw-text-xs lg:tw-text-desk-lg'>Delete My Account</p>
                                                </Link>


                                            </div>
                                        </div>
                                    </div>
                                </motion.div>

                            </div>
                        </Col>
                    </Row>
                </div>
                <motion.div
                    className="box"
                    ref={ref}
                    initial={{ opacity: 0 }} // Initial position and opacity
                    animate={inView ? "visible" : "hidden"} // Target position and opacity
                    variants={AnimationVariants2}
                    transition={{
                        duration: 1.3,
                        delay: 0.25,
                        ease: [0, 0.71, 0.2, 1.01],
                    }}
                >
                </motion.div>
            </section>
            <Modal
                centered
                closable={false}
                open={isModal}
                className=''
                onOk={() => setIsModal(false)}
                onCancel={() => setIsModal(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}

            >
                <section className='tw-p-10'>
                    <div className='tw-flex tw-justify-between '>
                        <p className='tw-text-primary_text tw-font-primaryFonts tw-font-semibold tw-text-[22px] tw-leading-[130%]'>Contact Us</p>
                        <div className='tw-cursor-pointer' onClick={() => setIsModal(prev => !prev)}>
                            <img src={pc_remove} alt='stockpe global contact close' />
                        </div>

                    </div>
                    <div className='tw-mt-8'>
                        <p className='tw-font-secondaryFonts tw-text-secondary_text tw-text-opacity-60 tw-text-desk-lg tw-font-medium tw-tracking-[-0.3px]'> Email</p>
                        <a href='mailto:support@stockpe.in' className='tw-mt-2 tw-font-secondaryFonts hover:tw-text-secondary_text  tw-text-secondary_text tw-text-desk-lg-1'>
                            support@stockpe.in
                        </a>
                    </div>
                </section>
            </Modal>
        </>
    )
}

export default QuickLinks