import React from 'react'

const Journey = () => {
    return (
        <section className='tw-bg-primaryBg '>

            <div className='tw-py-10 md:tw-py-8 lg:tw-py-[7.5rem]  tw-max-w-screen-3xl 3xl:tw-mx-auto tw-mx-5 lg:tw-mx-20 tw-grid tw-grid-cols-1 tw-gap-16'>
                {/* Video */}
                <div className='tw-hidden md:tw-block'>
                    <div className=''>

                        <div className='tw-relative' >
                            <div className='video-gradient-top' />
                            <div className='video-gradient-bottom' />
                            <div className='video-gradient-left' />
                            <div className='video-gradient-right' />
                            <video autoPlay loop muted className="">
                                <source src="https://stockpe-global.s3.ap-south-1.amazonaws.com/assets/globe_website_animation.mp4" type="video/mp4" />
                                {/* Add additional source elements if needed for different video formats */}
                                Your browser does not support the video tag.
                            </video>
                        </div>

                    </div>
                </div>
                {/* Text */}
                <div className='md:tw-text-center lg:tw-px-8 xl:tw-px-10 2xl:tw-px-40'>
                    <p className='tw-font-primaryFonts tw-text-secondary_text 320:tw-text-[1.725rem] tw-text-[2rem] 425:tw-text-[2.3rem] lg:tw-text-[2rem] xl:tw-text-[2.5rem] tw-leading-[130%] tw-font-bold'>
                        Helping you become more Experienced with your <span className='tw-text-primary_text'>Stock Market</span> and <span className='tw-text-primary_text'>Crypto Trading</span>
                    </p>
                    <p className='tw-my-6 xl:tw-my-8 tw-font-secondaryFonts tw-text-opacity-80 tw-text-secondary_text 320:tw-text-[0.8rem] lg:tw-text-[0.9rem]  xl:tw-text-[1.05rem] tw-leading-[125%]'>
                        Using StockPe, you&rsquo;ll learn how to build your virtual stock market portfolio for the first time, engage in trades to understand how they will perform in the real market, and how best to buy and sell shares to make a profit.
                    </p>
                    <p className='tw-font-secondaryFonts tw-text-opacity-80 tw-text-secondary_text 320:tw-text-[0.8rem] lg:tw-text-[0.9rem] xl:tw-text-[1.05rem] tw-leading-[125%]'>
                        With no risk, you can become an expert market trader in no time, building experience as you compete in tournaments against players across the globe while becoming more efficient and profitable from your trades.
                    </p>
                </div>
            </div>


            <div className='journey_layout tw-hidden md:tw-block' />

        </section>
    )
}

export default Journey