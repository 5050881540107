import React from 'react'
import checkbox from '../../assets/images/checkbox.webp'
import stockPe_affiliate from '../../assets/images/stockPe_affiliate.webp'

const DownloadApp = () => {
    return (
        <>
            {/* Mobile View */}
            <section className='tw-bg-primaryBg tw-block md:tw-hidden tw-pb-[62px] tw-px-4'>
                <p className='tw-font-primaryFonts tw-font-semibold tw-text-primary_text tw-text-[28px] 320:tw-text-[24px] tw-leading-125'>
                    Earn Big<span className='tw-text-secondary_text'> as a StockPe <br />Affiliate!</span>
                </p>
                <div className='my-3'>
                    <div className='tw-flex tw-my-2 tw-gap-3'>
                        <div>
                            <img src={checkbox} width={40} height={40} alt='checked' />
                        </div>
                        <p className='tw-font-secondaryFonts  tw-text-secondary_text tw-text-[15px] tw-leading-125 tw-font-light'>Download the StockPe app for free. Once you're in the app, navigate to the "Refer and earn" section.</p>
                    </div>
                    <div className='tw-flex tw-my-2 tw-gap-3'>
                        <div>
                            <img src={checkbox} width={40} height={40} alt='checked' />
                        </div>
                        <p className='tw-font-secondaryFonts tw-text-secondary_text tw-text-[15px] tw-leading-125 tw-font-light'>This section provides a unique referral link you can share with friends, family and followers.</p>
                    </div>
                    <div className='tw-flex tw-my-2 tw-gap-3'>
                        <div>
                            <img src={checkbox} width={40} height={40} alt='checked' />
                        </div>
                        <p className='tw-font-secondaryFonts tw-text-secondary_text tw-text-[15px] tw-leading-125 tw-font-light'>Earn a percentage of the profit every time someone you refer joins and plays with us!</p>
                    </div>
                </div>


                <div className='tw-mt-8 '>
                    <a href='https://stockpe.go.link/fC1Fc' target='_blank' rel='noreferrer'>
                        <button className='tw-bg-primary_text mobile-button-animation  tw-w-full tw-font-secondaryFonts tw-px-4 tw-py-4 tw-rounded-md'>
                            <div className='tw-flex tw-justify-center tw-items-center'>
                                <span className='tw-text-desk-lg -tw-tracking-[0.3px] tw-font-medium tw-mr-3'>Signup & Earn Now</span>
                            </div>
                        </button>
                    </a>
                </div>

            </section>

            {/* Desktop View */}
            <section className='tw-bg-primaryBg  tw-hidden md:tw-block tw-px-8 tw-py-[100px] xl:tw-px-[80px]'>
                {/* <div className='tw-grid  tw-px-12 tw-bg-[#5C0012] tw-rounded-[56px] tw-max-w-screen-3xl 3xl:tw-mx-auto   tw-grid-cols-2'>
                    <div className='tw-pl-0 xl:tw-pl-16 tw-flex tw-flex-col tw-justify-center'>
                        <p className='tw-font-primaryFonts tw-text-secondary_text tw-text-[22px] tw-leading-125'>
                            App is available for free
                        </p>
                        <p className='tw-font-primaryFonts tw-mt-8 tw-mb-8 tw-font-bold tw-text-secondary_text tw-text-[36px] tw-leading-[120%]'>
                            Download <br />Now!
                        </p>
                        <div className="tw-hidden md:tw-block">
                            <Row gutter={[8, 16]}>
                                <Col span={6} md={9} xl={8}>
                                    <Link
                                        target="_blank"
                                        rel="noreferrer"
                                        to="https://stockpe.go.link/fC1Fc"
                                    >
                                        <img
                                            className="tw-cursor-pointer  buttons"
                                            src={apple}
                                            width={165}

                                            alt="app store stokcPe"
                                        />

                                    </Link>
                                </Col>
                                <Col span={6} md={9} xl={8}>
                                    <Link
                                        target="_blank"
                                        rel="noreferrer"
                                        to="https://stockpe.go.link/fC1Fc"
                                    >
                                        <img
                                            className="tw-cursor-pointer buttons"
                                            src={android}
                                            width={165}

                                            alt="play store stokcPe"
                                        />
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='tw-pr-0 xl:tw-pr-6'>
                        <div className='tw-pt-8'>
                            <img src={app} alt='Download Stockpe App' />
                        </div>
                    </div>
                </div> */}

                <div className='tw-max-w-screen-3xl 3xl:tw-mx-auto'>
                    <img src={stockPe_affiliate} alt='stockPe_affiliate' />
                </div>
            </section>
        </>
    )
}

export default DownloadApp