import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import step_1 from '../../assets/images/step_1.webp'
import step_2 from '../../assets/images/step_2.webp'
import step_3 from '../../assets/images/step_3.webp'
import step_4 from '../../assets/images/step_4.webp'
import step_1_img from '../../assets/images/step_1_img.webp'
import step_2_img from '../../assets/images/step_2_img.webp'
import step_3_img from '../../assets/images/step_3_img.webp'
import step_4_img from '../../assets/images/step_4_img.webp'
import no1_img from '../../assets/images/no1.webp'
import no2_img from '../../assets/images/no2.webp'
import no3_img from '../../assets/images/no3.webp'
import no4_img from '../../assets/images/no4.webp'
import mobile_step_1_img from '../../assets/images/mobile_step_1_img.webp'
import mobile_step_2_img from '../../assets/images/mobile_step_2_img.webp'
import mobile_step_3_img from '../../assets/images/mobile_step_3_img.webp'
import mobile_step_4_img from '../../assets/images/mobile_step_4_img.webp'



const stepsArr = [
    {
        id: 1,
        name: 'Step 1'
    },
    {
        id: 2,
        name: 'Step 2'
    },
    {
        id: 3,
        name: 'Step 3'
    },
    {
        id: 4,
        name: 'Step 4'
    },
]

const data = [
    {
        id: 1,
        title: 'Select A Tournament',
        description: 'Choose a tournament you want to play.',
        step_image: step_1,
        image: step_1_img,
        numberImg: no1_img,
        mobileImg: mobile_step_1_img
    },
    {
        id: 2,
        title: 'Create a Portfolio',
        description: 'Create a portfolio of stocks in real time',
        step_image: step_2,
        image: step_2_img,
        numberImg: no2_img,
        mobileImg: mobile_step_2_img
    },
    {
        id: 3,
        title: 'Rank on leaderboard',
        description: 'Get rank on leaderboard and compete with other users.',
        step_image: step_3,
        image: step_3_img,
        numberImg: no3_img,
        mobileImg: mobile_step_3_img
    },
    {
        id: 4,
        title: 'Secure Withdrawals',
        description: 'Withdraw your winnings in your bank accounts securely.',
        step_image: step_4,
        image: step_4_img,
        numberImg: no4_img,
        mobileImg: mobile_step_4_img
    },
]
let a = 1;


const HowToPlay = () => {
    const [active, setActive] = useState(1)


    const optionHandler = (id) => {
        setActive(id)
    }
    useEffect(() => {
        const interval = setInterval(() => {
            if (a < 4) {
                ++a
                setActive(a)
            }
            else {
                a = 1;
                setActive(a)
            }
        }, 8 * 1000)
        return () => clearInterval(interval);
    }, [])
    return (
        <>
            {/* Mobile View and Tab View */}
            <section className='tw-bg-primaryBg how-to-play-layout-mobile tw-block lg:tw-hidden tw-py-10'>

                <div className=' 320:tw-px-4 tw-px-7 425:tw-px-10 md:tw-px-40 tw-py-[52px] '>

                    <div className='tw-mb-28'>
                        <p className='tw-font-primaryFonts tw-font-bold tw-text-center tw-text-secondary_text tw-text-[34px] tw-leading-125'>How to <span className='tw-text-primary_text'>Play?</span> </p>
                    </div>
                    <div>
                        {data?.map(ele => <div key={ele.id} className={`tw-bg-[#505646] tw-border tw-border-[#FBFFFF4D] tw-px-3 ${ele.id !== 4 && 'tw-mb-32'} tw-pt-[52px] tw-pb-6 tw-rounded-[11px]`}>
                            <div className='tw-relative'>
                                <img src={ele.numberImg} className='tw-absolute -tw-top-[70px] tw-left-[10px]' width={52} height={52} alt='how to play' />
                                <img src={ele.mobileImg} className={`tw-absolute ${(ele.id === 1 || ele.id === 2) && '-tw-top-[110px] -tw-right-[16px]'} ${(ele.id === 4) && '-tw-top-[155px] tw-max-w-[150px] tw-right-[0px]  tw-max-h-[150px]'} ${(ele.id === 3) && '-tw-top-[135px]  tw-right-[0px] '}  `} width={164} height={116} alt='how to play' />
                            </div>
                            <p className='tw-font-primaryFonts tw-font-bold tw-text-secondary_text tw-text-[18px] tw-leading-125'>{ele.title}</p>
                            <p className='tw-font-secondaryFonts tw-mt-3  tw-text-secondary_text tw-text-[13px] tw-leading-[130%]'>{ele.description}</p>
                        </div>)}
                    </div>
                </div>

            </section>


            {/* Desktop  */}
            <section className='tw-bg-primaryBg  tw-py-[100px]  tw-hidden lg:tw-block '>

                <div className='tw-max-w-screen-3xl how-to-play-layout 3xl:tw-mx-auto  tw-mx-20 tw-rounded-[54px] tw-px-[60px] tw-py-16 xl:tw-py-[100px] '>
                    <div className='tw-h-[35rem] 3xl:tw-h-[40rem]'>
                        <div className='tw-px-[60px]'>
                            <p className='tw-font-primaryFonts tw-font-bold xl:tw-text-[56px] tw-text-[36px] tw-leading-125 tw-text-secondary_text tw-text-center'>
                                How To <span className='tw-text-primary_text'>Play?</span>
                            </p>
                        </div>
                        <Row className='tw-mt-10 xl:tw-mt-[120px]' gutter={[64, 16]}>
                            <Col span={11} lg={13} xl={12}>
                                {data?.map(ele => <div key={ele.id} className={`tw-mt-4 ${ele.id === 4 && 'tw-ml-[4.5rem] 3xl:tw-ml-28'} ${active === ele.id ? 'tw-block' : 'tw-hidden'}`}>
                                    <img src={ele.image} className={`${ele.id === 4 && 'tw-max-h-[400px] 2xl:tw-max-h-[500px]'} tw-max-h-[350px] 2xl:tw-max-h-[450px]`} alt='how to play' />
                                </div>)}
                            </Col>
                            <Col span={13} lg={11} xl={12}>
                                <div>
                                    {/* Steps */}
                                    <div className='tw-flex gap-4'>
                                        {stepsArr?.map(ele => <div onClick={() => {
                                            optionHandler(ele.id)
                                        }} key={ele.id} className={`${active === ele.id ? 'tw-bg-secondary_text tw-text-primaryBg' : 'tw-text-secondary_text tw-bg-[#4D4D4D]'}  tw-px-2 xl:tw-px-4 tw-py-1.5 tw-cursor-pointer  tw-rounded-[30px] tw-font-secondaryFonts tw-font-medium tw-text-[12px] xl:tw-text-[17px] tw-leading-125`}> {ele.name} </div>)}
                                    </div>
                                    <div className='tw-mt-20'>
                                        {data?.map(ele => <div key={ele.id} className={`tw-bg-[#505646] tw-border tw-border-[#FBFFFF4D] tw-pt-[55px] xl:tw-pt-[72px] tw-pl-4 tw-pr-0 xl:tw-px-6 tw-pb-[24px] tw-rounded-[11px] ${ele.id === active ? 'tw-block' : 'tw-hidden'}`}>
                                            <img src={ele.step_image} className='tw-absolute tw-w-[60px] tw-h-[60px] xl:tw-w-[70px] xl:tw-h-[70px] tw-top-[75px] ' width={70} height={70} alt='how to play' />
                                            <p className='tw-text-secondary_text tw-font-primaryFonts tw-font-bold  tw-text-[24px] xl:tw-text-[34px] tw-leading-125'>
                                                {ele.title}
                                            </p>
                                            <p className='tw-font-primaryFonts tw-mt-3 tw-text-secondary_text tw-text-[15px] xl:tw-text-[20px] tw-leading-125'>
                                                {ele.description}
                                            </p>
                                        </div>)}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section >
        </>
    )
}

export default HowToPlay