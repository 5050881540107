import React, { useState } from 'react'
import support from '../../assets/images/support.webp'
import yellow_arr from '../../assets/images/yellow_arr.webp'
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { Modal } from 'antd';
import pc_remove from '../../assets/images/pc_menu.webp'

const Support = () => {
    const [isModal, setIsModal] = useState(false)
    const [mobileModal, setMobileModal] = useState(false)
    const { ref, inView } = useInView({
        triggerOnce: true, // Animation triggers only once
        threshold: 0.35, // Percentage of element visibility to trigger the animation
    });
    const [mobileRef, mobileInView] = useInView({
        triggerOnce: true, // Animation triggers only once
        threshold: 0.35, // Percentage of element visibility to trigger the animation


    });
    const AnimationVariants = {
        hidden: { opacity: 0, },
        visible: { opacity: 1, },
    };
    ;
    return (
        <>

            {/* Mobile View */}
            <section className='tw-bg-primaryBg tw-block md:tw-hidden '>

                <motion.div
                    className={`box ${mobileInView ? 'support-animation' : ''}`}
                    ref={mobileRef}
                    initial={{ opacity: 0, }} // Initial position and opacity
                    animate={mobileInView ? "visible" : "hidden"} // Target position and opacity
                    variants={AnimationVariants}
                    transition={{
                        duration: 1.4,
                        delay: 0.25,
                        ease: [0, 0.71, 0.2, 1.01],
                    }}>
                    <div className='tw-mx-5 tw-pt-10 tw-pb-0.5'>
                        {/* Text Div */}
                        <div className=''>

                            <div className='tw-my-3 '>
                                <p className='tw-font-primaryFonts tw-text-secondary_text  tw-text-[34px] 320:tw-text-[30px] tw-leading-[125%] tw-font-bold'>
                                    <span className='tw-text-primary_text'>  24 Hours</span> Support
                                </p>

                            </div>
                            <div className='tw-mb-4'>
                                <p className='tw-font-secondaryFonts tw-text-secondary_text tw-text-[14px] tw-font-light  tw-leading-[130%]'>Feel Free to chat with our support team whenever <br className='tw-hidden xs:tw-block' /> you need clarity or assistance</p>
                            </div>
                            <div className='tw-my-10 '>
                                <button onClick={() => setMobileModal(prev => !prev)} className='tw-text-primary_text contact-button-animation tw-border tw-border-primary_text tw-bg-transparent tw-w-full  tw-px-5 tw-py-3 tw-rounded-md'>
                                    <div className='tw-flex tw-justify-center tw-items-center'>
                                        <span className='tw-text-desk-lg tw-font-secondaryFonts -tw-tracking-[2%] tw-font-medium tw-mr-3'>Connect With Us</span>
                                        <img className='tw-inline-block' src={yellow_arr} alt='arrow' />
                                    </div>
                                </button>
                            </div>
                            <div className='tw-mb-10 tw-flex tw-justify-center '>
                                <img src={support} alt='stocks' />
                            </div>


                        </div>
                    </div>
                </motion.div>
            </section>
            <Modal
                centered
                closable={false}
                open={mobileModal}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}

            >
                <section className='tw-py-5 tw-px-4'>
                    <div className='tw-flex tw-justify-between tw-items-center'>
                        <p className='tw-text-primary_text tw-font-primaryFonts tw-font-semibold tw-text-[20px] tw-leading-[130%]'>Contact Us</p>
                        <div className='tw-cursor-pointer' onClick={() => setMobileModal(prev => !prev)}>
                            <img src={pc_remove} alt='stockpe global contact close' />
                        </div>

                    </div>
                    <div className='tw-mt-8'>
                        <p className='tw-font-secondaryFonts tw-text-secondary_text tw-text-opacity-60 tw-text-[13px] tw-leading-[130%] tw-font-medium '> Email</p>
                        <a href='mailto:support@stockpe.in' className='tw-mt-2 tw-font-secondaryFonts hover:tw-text-secondary_text tw-font-light tw-text-secondary_text tw-text-desk-lg tw-tracking-[0.15px] tw-underline'>
                            support@stockpe.in
                        </a>
                    </div>
                </section>
            </Modal>


            {/* Tab & Desktop View */}
            <section className='tw-bg-primaryBg tw-hidden md:tw-block ' >
                <motion.div
                    className="box"
                    ref={ref}
                    initial={{ opacity: 0, }} // Initial position and opacity
                    animate={inView ? "visible" : "hidden"} // Target position and opacity
                    variants={AnimationVariants}
                    transition={{
                        duration: 1.2,
                        delay: 0.40,
                        ease: [0, 0.71, 0.2, 1.01],
                    }}
                >
                    <div className='tw-pt-40   tw-max-w-screen-3xl 3xl:tw-mx-auto  tw-mx-20'>
                        <div className="tw-grid tw-grid-cols-2 tw-gap-5  lg:tw-gap-20">

                            <div className='tw-flex tw-justify-between tw-items-center'>

                                <div className={`${inView ? 'support-animation' : ' '}`}>
                                    <div className='tw-mt-12 tw-mb-6'>
                                        <p className='tw-font-primaryFonts tw-text-secondary_text tw-text-[35px]  lg:tw-text-[45px] xl:tw-text-[62px] tw-leading-[125%] tw-font-bold'>
                                            <span className='tw-text-primary_text'> 24 Hours </span> <br className='tw-block 3xl:tw-hidden' />  Support
                                        </p>

                                    </div>
                                    <div className='tw-mb-10 '>
                                        <p className='tw-font-secondaryFonts tw-text-secondary_text md:tw-text-[13px] lg:tw-text-base xl:tw-text-desk-lg-3 '>Feel Free to chat with our support team  <br className='tw-hidden lg:tw-block' />whenever you need clarity or assistance</p>

                                    </div>
                                    <div className=''>
                                        <button onClick={() => setIsModal(prev => !prev)} className='tw-text-primary_text click-arrow  tw-bg- tw-border tw-border-primary_text tw-font-secondaryFonts tw-pl-5 tw-pr-4 md:tw-py-2 lg:tw-py-3 tw-rounded-md hover:tw-bg-contact_btn'>
                                            <div className='tw-flex tw-justify-between tw-items-center'>
                                                <span className='lg:tw-text-desk-lg tw-text-[14px] tw-font-medium tw-mr-3'>Connect With Us</span>
                                                <img className='tw-inline-block mv-arrow click-arrow' src={yellow_arr} alt='arrow' />
                                            </div>
                                        </button>
                                    </div>

                                </div>
                            </div>
                            {/* Image Col */}
                            <div className='tw-flex tw-justify-start '>
                                <img src={support} className='' alt='stocks' />
                            </div>

                            {/* Text Col */}




                        </div>
                    </div>
                </motion.div>
            </section>
            <Modal
                centered
                closable={false}
                open={isModal}
                className=''
                onOk={() => setIsModal(false)}
                onCancel={() => setIsModal(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}

            >
                <section className='tw-p-10'>
                    <div className='tw-flex tw-justify-between '>
                        <p className='tw-text-primary_text tw-font-primaryFonts tw-font-semibold tw-text-[22px] tw-leading-[130%]'>Contact Us</p>
                        <div className='tw-cursor-pointer' onClick={() => setIsModal(prev => !prev)}>
                            <img src={pc_remove} alt='stockpe global contact close' />
                        </div>

                    </div>
                    <div className='tw-mt-8'>
                        <p className='tw-font-secondaryFonts tw-text-secondary_text tw-text-opacity-60 tw-text-desk-lg tw-font-medium tw-tracking-[-0.3px]'> Email</p>
                        <a href='mailto:support@stockpe.in' className='tw-mt-2 tw-font-secondaryFonts hover:tw-text-secondary_text  tw-text-secondary_text tw-text-desk-lg-1'>
                            support@stockpe.in
                        </a>
                    </div>
                </section>
            </Modal>

        </>
    )
}

export default Support