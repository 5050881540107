import React, { useState } from 'react'
import { Col, Collapse, Row } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import arrow from '../../assets/images/white-arrow-2.webp'

const FAQ = () => {
    const [activeKey, setActiveKey] = useState(['1']);

    const onChange = (key) => {
        setActiveKey(key[key?.length - 1]);
    };
    const titleClass = 'tw-text-[#F3ECF6] tw-pl-1 tw-font-secondaryFonts tw-font-semibold lg:tw-font-medium 320:tw-text-[13px] tw-text-[16px] tw-leading-125'
    const childClass = 'tw-text-[#F3ECF6] tw-text-justify !tw-py-1 !tw-pl-[60px] tw-font-secondaryFonts md:tw-font-extralight tw-font-extralight 320:tw-font-light xs:tw-font-light 320:tw-text-[11px] tw-text-[14px] tw-leading-[150%]'

    const items = [
        {
            key: '1',
            label: <p className={titleClass}>What is Fantasy Stock Market?</p>,
            children: <p className={childClass}>Fantasy Stock Market is a strategy-based virtual stock market game in which you can create a team of stocks and crypto and play in live tournaments worldwide. You rank based on profit made and win cash prizes based on your team's performances in the actual stock or crypto market.</p>,
        },
        {
            key: '2',
            label: <p className={titleClass}>Is it safe to deposit money in StockPe?</p>,
            children: <p className={childClass}>Depositing to your StockPe account is simple and safe. We have enabled many different payment options on StockPe to ensure that your personal details are safe with us.<br />What&rsquo;s more? After verifying your personal details, you can withdraw the money you win on StockPe directly to your bank account.
                Our wallets are powered by Stripe and PayPal.</p>,
        },
        {
            key: '3',
            label: <p className={titleClass}>When do I get my winnings?</p>,
            children: <p className={childClass}>A successfully processed withdrawal request should get credited within 3-5 working days.<br />For more details, Please reach out to our <a href='mailto:support@stockpe.in' className='tw-text-[#3C7FFF] hover:tw-text-[#3C7FFF]' target='_blank' rel='noreferrer'  >
                support.
            </a></p>,
        },
        {
            key: '4',
            label: <p className={titleClass}>How should I download the StockPe app?</p>,
            children: <p className={childClass}>There are 3 ways in which you can download the StockPe app.
                Head to www.stockpe.app on your desktop or mobile browser and click "Try for Free." If you're an iOS user, you can also search for 'StockPe' in the App Store and download the app. If you are an Android user, you can also search for the 'StockPe' on the Play Store and download the app.</p>,
        },
        {
            key: '5',
            label: <p className={titleClass}>Which countries stocks and stock markets are available to play?</p>,
            children: <p className={childClass}>StockPe offers US stock market stocks and recently introduced cryptocurrencies on the platform. We will soon add more markets and assets to play with.</p>,
        },
        {
            key: '6',
            label: <p className={titleClass}>What happen inside a tournament?</p>,
            children: <p className={childClass}>A tournament is a virtual playground with a timer. You get a real-time list of virtual stocks or crypto to build your team. You compete with other players worldwide to see who makes the highest profits.</p>,
        },
        {
            key: '7',
            label: <p className={titleClass}>Can i play on StockPe?</p>,
            children: <p className={childClass}>If you're 18 or above and already have some knowledge of the stock or crypto market, StockPe is the perfect place to be. We recommend that users have prior knowledge about the stock or crypto market to play and win big.<br />For a beginner, StockPe provides a realistic and risk-free environment to practice trading strategies, gain insights, and earn rewards.</p>,
        },
    ];
    return (
        <>
            {/* Mobile View */}

            <section className='tw-bg-primaryBg tw-pt-[32px] tw-pb-[54px] tw-block xl:tw-hidden'>
                <div className='tw-py-6 tw-px-5'>
                    <p className='tw-font-secondaryFonts tw-flex tw-items-center gap-1 tw-my-[10px] tw-font-bold tw-text-secondary_text tw-text-[12px] tw-leading-125'>

                        NEED TO KNOW <img src={arrow} width={20} alt='FAQS' />
                    </p>

                    <p className='tw-font-primaryFonts tw-font-bold tw-text-primary_text tw-text-[34px] 320:tw-text-[30px] tw-leading-125'>
                        Frequently Asked<br /><span className='tw-text-secondary_text'>Questions</span>
                    </p>
                </div>
                <div className='tw-bg-[#00382F] tw-p-8'>

                    <Collapse
                        items={items}
                        bordered={false}
                        ghost
                        onChange={onChange}
                        activeKey={activeKey}
                        expandIcon={({ isActive }) => isActive ? <MinusOutlined className='!tw-text-[#F3ECF6] !tw-text-[25px]' /> : <PlusOutlined className='!tw-text-[#F3ECF6] !tw-text-[25px]' />}
                    />
                </div>
            </section>

            {/* Desktop View */}
            <section className='tw-bg-primaryBg tw-hidden xl:tw-block  tw-py-[98px] tw-px-[120px]'>
                <div className='tw-max-w-screen-3xl 3xl:tw-mx-auto  '>
                    <Row gutter={[64, 16]}>
                        <Col className='tw-flex tw-flex-col tw-justify-center' span={12} xl={12} xxl={12}>


                            <p className='tw-font-secondaryFonts tw-flex tw-items-center gap-1 tw-my-[10px] tw-font-medium tw-text-secondary_text tw-text-[13px] xl:tw-text-[14px] tw-leading-125'>

                                NEED TO KNOW <img src={arrow} width={20} alt='FAQS' />
                            </p>

                            <p className='tw-font-primaryFonts tw-font-bold tw-text-primary_text tw-text-[36px]  xl:tw-text-[52px] tw-leading-[118%]'>
                                Frequently Asked<br /><span className='tw-text-secondary_text'>Questions</span>
                            </p>


                        </Col>
                        <Col span={12} xl={12} xxl={12}>
                            <div className='tw-bg-[#00382F] tw-rounded-[54px] tw-py-8 tw-px-10'>

                                <Collapse
                                    items={items}
                                    bordered={false}
                                    ghost
                                    onChange={onChange}
                                    activeKey={activeKey}
                                    expandIcon={({ isActive }) => isActive ? <MinusOutlined className='!tw-text-[#F3ECF6] !tw-text-[25px]' /> : <PlusOutlined className='!tw-text-[#F3ECF6] !tw-text-[25px]' />}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>

    )
}


export default FAQ